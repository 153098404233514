<template>
  <div class="Register_container">
    <div class="register_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" alt="">
      </div>
      <!-- 注册表单区域 -->
      <el-form ref="registerFormRef" :rules="rules" :model="registerForm" label-width="0px" class="register_from">
        <!-- 用户 -->
        <el-form-item prop="name">
          <el-input v-model="registerForm.name" prefix-icon="el-icon-user-solid" placeholder="请输入用户" />
        </el-form-item>
        <!-- 电话号码 -->
        <el-form-item prop="username">
          <el-input v-model="registerForm.username" prefix-icon="el-icon-phone-outline" placeholder="请输入电话号码" />
        </el-form-item>
        <!-- 短信验证码 -->
        <el-form-item prop="smsCode">
          <el-input class="verification-num" v-model="registerForm.smsCode" prefix-icon="el-icon-key" type="num" placeholder="请输入验证码"/>
          <el-button v-show="show" class="verification" type="primary" @click="getCode">获取验证码</el-button>
          <el-button v-show="!show" disabled type="primary" >重新发送{{count}}秒</el-button>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="registerForm.password" prefix-icon="el-icon-lock" type="password" placeholder="请设置密码" />
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="registerForm.email" prefix-icon="el-icon-lock" placeholder="请设置邮箱" />
        </el-form-item>
        <!-- 确认密码 -->
        <!-- <el-form-item prop="confirmpassword">
          <el-input v-model="registerForm.confirmpassword" prefix-icon="el-icon-lock" type="password" placeholder="请确认密码" />
        </el-form-item> -->
        
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button class="btns-button" type="primary" @click="register">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {post} from '../network/api'
var validatorEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!reg.test(value)) {
      callback(new Error('Email格式不对'))
    }
    callback()
  }
}
var validatorphone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
    if (!reg.test(value)) {
      callback(new Error('手机号格式不对'))
    }
    callback()
  }
}
export default {
  data() {
    return {
      // 这是注册表单的数据绑定对象
      registerForm: {
        username: '',//手机号
        name:'',
        email:'',
        password: '',
        smsCode:'',
      },
      rules:{
        username:[{ required: true, validator: validatorphone, trigger: 'blur'}],
        name:[{ required: true, message: '请输入用户名', trigger: 'blur'}],
        email:[{ required: true, validator: validatorEmail, trigger: 'blur'}],
        password:[{ required: true, message: '请输入密码', trigger: 'blur'}],
        smsCode:[{ required: true, message: '请输入验证码', trigger: 'blur'}],
      },
      show:true,
      count: '',
      timer: null
    }
  },
  methods: {
    // 发送验证码
    getCode(){
      const TIME_COUNT = 60;
     if (!this.timer && this.registerForm.username !=='') {
        post('/regSms',{
        userName:this.registerForm.username
      }).then(res=>{
        console.log(res);
        this.$message({
          message:res.data.msg
        })
      }).catch(err=>{
        console.log(err);
      })
       this.count = TIME_COUNT;
       this.show = false;
       this.timer = setInterval(() => {
       if (this.count > 0 && this.count <= TIME_COUNT) {
         this.count--;
        } else {
         this.show = true;
         clearInterval(this.timer);
         this.timer = null;
        }
       }, 1000)
      }else{
        // this.$message('手机号为空')
      }
     
    },
    // 点击注册按钮
    register() {
      this.$refs.registerFormRef.validate((valid) => {
        if (valid) {
          post('/register',{
            "name":this.registerForm.name,
            "userName":this.registerForm.username,
            "password":this.registerForm.password,
            "email":this.registerForm.email,
            "smsCode":this.registerForm.smsCode
          }).then(res=>{
          console.log(res)
          if(res.status == 200)
          {
            this.$message({
              message: '注册成功',
              type: 'success'
            });
            this.$router.push('/login')
          }
          }).catch(err=>{
            console.log(err)
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.Register_container{
    //background-color:lightseagreen;
    background-image: url('../assets/bg1.jpg');
    opacity: 0.8;
    height: 100%;
}
.register_box{
    width: 450px;
    height: 500px;
    background-color: white;
    border-radius: 3px;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar_box{
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:white;
        img{
            widows: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
}
.btns{
    display: flex;
    justify-content: center;
    .btns-button{
        width: 250px;
        height: 50px;
        font-size: 18px;
    }
}
.register_from{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .verification-num{
        width: 50%;
    }
    // .verification{
    //     // float: left;
    // }
}
</style>
